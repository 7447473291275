import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResultPanel from './ResultPanel';
import Carousel from 'carousel-react-rcdev';



const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  ul {
    li {
      list-style: none;
    }
  }
  margin-bottom: 3rem;
  [aria-hidden="true"] {
    visibility: hidden!important;
  }
  
  @media (max-width: 768px) {
    padding: 0;
    h2 {
      margin-left: 1rem;
    }
  }
`

const LocationCarousel = ({ tenant, services, types }) => {
    const [locations, setLocations] = useState();
  useEffect(() => {
    const getLocations = async()=>{
        const resp = await fetch(`https://hasura.decodedigital.co/api/rest/get-locations/${tenant}`,{
            body: JSON.stringify({
                serviceLines: services ? services.split(',') : '',
                locationTypes: types ? types.split(',') : ''
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });
        const locs = await resp.json()
        console.log(resp)
        setLocations(locs)
    }
    getLocations()
  }, []);


  return(
    <>
        {
            locations && 
            <CarouselContainer>
                <Carousel
                >
                    {locations?.location.map(card => <ResultPanel key={card.slug} location={card} tenant={tenant} isSmall={true}/>
                    )}
                </Carousel>
            </CarouselContainer>
        }
    </>
  )
};

LocationCarousel.propTypes = {
  tenant: PropTypes.string.isRequired,
  serviceLines: PropTypes.array.isRequired,
  locationTypes: PropTypes.array.isRequired
};

export default LocationCarousel;



import React from "react";

import styled from "styled-components";



const ResultPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 0.1rem solid #e3e1dc;
  margin-bottom: 2.4rem;
  min-height: ${({isSmall}) => isSmall ? '44rem' : '25rem'};
  flex-direction: ${({isSmall}) => isSmall ? 'column':'row'};
  margin-top: ${({isSmall}) => isSmall ? '10rem':'unset'};
  align-self: ${({isSmall}) => isSmall ? 'unset' : 'flex-end'};
  max-width: ${({isSmall}) => isSmall ? '90%' : '90%'};
  min-width: ${({isSmall}) => isSmall ? '40%' : '90%'};
  margin-right: 1.5rem;
  &:last-child {
      margin-right: 0;
  }
  h2 {
    min-height: ${({isSmall}) => isSmall ? '10rem' : 'unset'};
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10rem;
    margin-left: 0;
    justify-content: flex-start;
    align-self: center ;
    min-width: 47%;
  }
  @media (max-width: 480px) {
    margin-right: 0;
    min-width: 100%;
    min-height: 53rem;
  }

`;

const ResultDetailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: ${({isSmall}) => isSmall? '100%': '85%'};
  flex-direction: ${({isSmall}) => isSmall? 'column' : 'row'} ;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
  height: 13rem;
  width: 13rem;
  min-width: 30%;
  margin-left: ${({isSmall}) => isSmall ? '0':'-7.5rem'};
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 18%);
  cursor: pointer;
  a{
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-40%, -30%) scale(0.5);
  }
  margin-top: ${({isSmall}) => isSmall? '-7.5rem': 'unset'};
  @media (max-width: 768px) {
    margin-top: -7.5rem;
    margin-left: 0;
  }
`;

const ResultDetails = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: ${({isSmall}) => isSmall? '2.6rem auto' : '2.6rem 3.6rem'};
  width: 80%;
  svg{
    margin-left: -1.2em;
    margin-right: .5rem;
  }
  h2 {
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
  }
  a:hover {
    color: #61A608;
    cursor: pointer;
  }
  .result__name-heading {
    color: #007785;
    cursor: pointer;
  }
  span {
    font-size: 1.4rem;
  }
  a {
    display: inline-block;
    margin-top: .5rem;
    color: #007785
  }
  .distance-from {
    font-size: 1.3rem ;
  }

  .result__addressing-information-main-container {
    a {
      font-size: 2rem;
      line-height: 2;
      padding: .5rem;
    }
  }

  text-align: ${({isSmall}) => isSmall? 'center' : 'unset'};
  @media (max-width: 768px) {
    text-align: center;
    margin: 2.6rem auto;
  }
`;

const ScheduleApptContainer = styled.div`
  display: flex;
  text-align: center ;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;;
  padding: 2rem;
  color: #007785;
  font-size: 1.8rem;
  line-height: 2.2rem;
  a {
    color: #007785;
    &:hover {
      color: #61A608;
    }
  }
  div {
    &:first-child {
      width: 40%;
      margin-right: 2rem;
      text-align: right;
      @media (max-width: 768px) {
        width: 20%;
      }
    }
  }
  .phone-number {
    font-size: 2rem;
    line-height: 2;
    display: inline-block;
    margin-top: 1rem;
  }
`;


const PhoneIcon = () => (
  <>
    <svg width="39" height="62" viewBox="0 0 39 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="36.75"
        height="60"
        rx="2"
        fill="white"
        stroke="#00606B"
        strokeWidth="2"
      />
      <rect
        x="2.58325"
        y="43.9167"
        width="33.5833"
        height="15.5"
        fill="#00606B"
        stroke="#00606B"
        strokeWidth="3"
      />
      <circle cx="19.375" cy="52.9583" r="3.875" fill="white" />
    </svg>
  </>
);

const AddressIcon = () => (
  <>
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.75239C0 2.57214 2.68286 0 6 0C9.31714 0 12 2.57214 12 5.75239C12 9.17917 8.21143 13.9043 6.66 15.704C6.31714 16.0985 5.69143 16.0985 5.34857 15.704C3.78857 13.9043 0 9.17917 0 5.75239ZM3.5 6.68275C3.5 8.15474 4.62 9.34939 6 9.34939C7.38 9.34939 8.5 8.15474 8.5 6.68275C8.5 5.21076 7.38 4.01611 6 4.01611C4.62 4.01611 3.5 5.21076 3.5 6.68275Z"
        fill="#24509A"
      />
    </svg>
  </>
);

function ResultPanel(props) {
  const {
    location: {
      photo_url: photoUrl,
      slug,
      name,
      address_1: address1,
      address_2: address2,
      city,
      state,
      zip,
      locationTypes,
      phone_number: phoneNumber,
      distance,
    },
    tenant
  } = props;


  return (
    <ResultPanelContainer className="result-panel-container" isSmall={props.isSmall}>
      <ResultDetailsContainer isSmall={props.isSmall}>
        <ImageContainer isSmall={props.isSmall}>
          {photoUrl &&
            <>
              {
                !props.isSmall ?
              <a href={`https://stlukeshealth.getfoundtechnologies.net/location/${slug}`}>
                <a aria-label="Location Image">
                  <img
                  src={photoUrl.includes('http')? photoUrl : `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}${photoUrl}`}
                  alt={name}
                />
                </a>
              </a> :
              <a aria-label="Location Image" href={`/location/${slug}`}>
                <img
                src={photoUrl.includes('http')? photoUrl : `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}${photoUrl}`}
                alt={name}
              />
              </a>
              }
            </>
          }
        </ImageContainer>
        <ResultDetails isSmall={props.isSmall}>
          {
            !props.isSmall ? <a href={`https://stlukeshealth.getfoundtechnologies.net/location/${slug}`}>
              <h2 className="result__name-heading">{props.isSmall? `${name.substring(0,50)}...` : name}</h2>
            </a> :
            <a aria-label="Location Link" href={`/location/${slug}`}>
            <h2 className="result__name-heading">{props.isSmall? `${name.substring(0,50)}...` : name}</h2>
            </a> 
          }
          {locationTypes && locationTypes[0] && (
            <p>{locationTypes[0]}</p>
          )}
          <div className="result__addressing-information-main-container">
            <AddressIcon /><span className="result__addressing-line-1">{`${address1},`}&nbsp;</span>
            {address2 && <span className="result__addressing-line-2">{`${address2}, `}</span>}
            <span className="result__addressing-city">{`${city},`}&nbsp;</span>
            <span className="result__addressing-state">{`${state}`}&nbsp;</span> 
            <span className="result__addressing-zip">{`${zip}`}</span>
            <br />
            <a aria-label="Get Direction" target={"_blank"} rel="noreferrer" href={`https://www.google.com/maps/dir//${(address1+city+state+zip).replaceAll(' ', '+')}`} >
              Get Directions
            </a>
            {
              distance &&
                  <p className="distance-from">Approximately {Math.round(distance)} miles from you</p>
            }
          </div>
        </ResultDetails>
      </ResultDetailsContainer>
      <ScheduleApptContainer isSmall={props.isSmall}>
        <a aria-label="Call Location" href={`tel:${phoneNumber?.replace(/\D/g, "")}`}>
          <div>{PhoneIcon()}</div>
        </a>
        <div>
          Call to make an appointment
          <br />
          <a aria-label="Call Location" className="phone-number" href={`tel:${phoneNumber?.replace(/\D/g, "")}`}>{phoneNumber}</a>
        </div>
      </ScheduleApptContainer>
    </ResultPanelContainer>
  );
}

export default ResultPanel;

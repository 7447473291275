
import React from 'react';
import ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';

import LocationCarousel from './components/LocationCarousel';

const Index = (props) => {
  return (
    <div>
      <LocationCarousel {...props}/>
    </div>
  )
};

Index.propTypes = {
  tenant: PropTypes.string.isRequired,
  services: PropTypes.string.isRequired,
  types: PropTypes.string.isRequired
};

customElements.define('decode-location-widget', reactToWebComponent(Index, React, ReactDOM));